import { useAtom } from 'jotai';
import { useControls } from 'leva'
import { kitchenLoadedAtom, sceneLoadingAtom } from '../state';
import { useEffect } from 'react';
import { EffectComposer, Selection, Outline, N8AO, TiltShift2, ToneMapping } from "@react-three/postprocessing"
import { konsole } from './utils';


export function Effects() {
	const size = useControls('Size', {
		width: 1920,
		height: 1080,
	})

	return (
		<EffectComposer disableNormalPass>
			<N8AO halfRes aoSamples={5} aoRadius={0.4} distanceFalloff={0.75} intensity={1} />
			{/* <Outline visibleEdgeColor="white" hiddenEdgeColor="white" blur width={size.width * 1.25} edgeStrength={10} /> */}
			{/* <TiltShift2 samples={9} blur={0.1} /> */}
			{/* <ToneMapping /> */}
		</EffectComposer>
	)
}

export function SetLoaded() {
	const [isLoaded, setIsLoaded] = useAtom(kitchenLoadedAtom);
	const [isSceneLoading, setSceneLoading] = useAtom(sceneLoadingAtom)
	useEffect(() => {
		konsole.log('LOADEDDD LOADEDDDDDDD LOADDEDDDD LOADEDDD LOADEDDDDDDD LOADDEDDDDLOADEDDD LOADEDDDDDDD LOADDEDDDD LOADEDDD LOADEDDDDDDD LOADDEDDDD LOADEDDD LOADEDDDDDDD LOADDEDDDD LOADEDDD LOADEDDDDDDD LOADDEDDDD')
		setIsLoaded(true)
		setSceneLoading(false)

	}, [])
	return null

}