import { useTranslation } from 'react-i18next';

export default function () {

    const { t, i18n } = useTranslation();

    const switchLang = (e) => {
        e.preventDefault();
        let newLang = i18n.language === 'en' ? 'fr' : 'en';
        localStorage.lng = newLang;
        i18n.changeLanguage(newLang);
        if (window._paq) {
            window._paq.push(['setCustomDimension', 1, newLang])
        }
    }

    return <button onClick={switchLang} className="text-dark-gray relative top-[-3px] py-2 px-4 border border-black bold text-xs">
        <span className={i18n.language === 'en' && 'text-accent' || ''}>EN</span> / <span className={i18n.language === 'fr' && 'text-accent' || ''}>FR</span>
    </button>
}