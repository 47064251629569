import LanguageSwitcher from "../components/LanguageSwitcher";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination, Fade, AutoPlay, Perspective, Arrow } from "@egjs/flicking-plugins";
import { useTranslation } from "react-i18next";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/flicking-plugins/dist/arrow.css";
import { useState, useRef } from "react";
import { konsole } from "../helpers/utils";

let restart = 0

export default function () {
	const [panel, setPanel] = useState(0)
	const $f = useRef(null);
	const kitchens = [
		{
			name: 'Refined Prestige',
			image: '/ui/kitchen1xnew.jpg',
			description: 'The pinnacle of exclusive elegance and luxury.',
			link: '/kitchen1',
		},
		{
			name: 'Urban Chic',
			image: '/ui/kitchen2xnew.jpg',
			description: 'Modern flair with a city vibe.',
			link: '/kitchen2',
		},
		{
			name: 'Sleek Serenity',
			image: '/ui/kitchen3xnew.jpg',
			description: 'Oasis of calm with clean lines and gleaming finishes.',
			link: '/kitchen3',

		},

	]

	++restart;
	const goNext = (e) => {
		$f.current.moveTo(panel + 1, 300)
	}
	const goPrev = (e) => {
		$f.current.moveTo(panel - 1, 300)
	}

	const onSelect = (e) => {
		const currentPanel = $f.current.getStatus().index
		const selected = e.index
		if (selected === currentPanel) return null

		$f.current.moveTo(selected, 300)

	}
	const onMoveStart = (e) => {
		document.querySelector('.start-btn').classList.remove('active')
	}
	const onMove = (e) => {
		document.querySelector('.start-btn').classList.add('active')
		let index = $f.current && $f.current.getStatus().index

	}
	const onChanged = (e) => {
		const index = e.currentTarget.getStatus().index;
		konsole.log('Current kitchen index:', index);
		setPanel(e.currentTarget.getStatus().index)
	}
	konsole.log('pANELL CHANGED', panel)


	const { t } = useTranslation();
	const plugins = [
		new Fade(null, 0.9),
		// new Perspective({ rotate: 0, scale: 1, perspective: 1 })
		// new Pagination({ type: 'bullet' }),
		// new Arrow(),
	];


	document.querySelector('#root').classList.add('overflow-scroll')
	document.querySelector('#root').classList.add('bg-white')
	document.querySelector('#root').classList.add('shadow-lg')



	return <div className="landing-wrapper text-dark-gray">
		<nav className="flex justify-between bg-white px-4 py-4 shadow-lg">
			<div>
				<img src="/ui/logo.svg" className="h-[2rem]" />
			</div>
			<div className="flex">
				{/* <img src="/ui/leaf.png" className="h-[35px] mr-2" /> */}
				<LanguageSwitcher />
			</div>
		</nav>
		<main className="main-container -[5vw]" style={{
			// backgroundColor: 'rgba(100,100,100,0.3)'
		}}>
			<div className="my-[4vw] text-center">
				<p className="text-xl md:text-2xl mt-4">{t('Introducing')}</p>
				<h1 className="text-[10vw] md:text-[3.5046729vw] bigbold">{t('3D Kitchen Configurator')}</h1>
			</div>

			<Flicking
				ref={$f}
				onSelect={onSelect}
				onReady={e => konsole.log('on Ready', e)}
				onMoveStart={onMoveStart}
				onMoveEnd={onMove}
				onChanged={onChanged}
				panelsPerView={1.4}
				className="mx-auto"
				circular={false}
				align={"center"}
				useFractionalSize={true}
				plugins={plugins}
			>
				{
					kitchens.map((kitchen, index) => {
						return <div className="card-panel kitchen-butto text-left flex flex-col w-full" key={index}>
							<div className="relative h-full">
								<img src={kitchen.image} role="presentation" className="h-full object-cover" />

								<p className="absolute bottom-0 left-0 m-1 p-2 text-black text-[12px] normal">
									{t('Images are for illustrative purposes only.')}
								</p>

							</div>
							<div className='mt-6 px-4 md:px-8 text-center'>
								<p className='text-xs bold text-accent'>{index + 1}/3</p>
								<h2 className='mb-2 uppercase'>{t(kitchen.name)}</h2>
							</div>
						</div>
					})
				}
				<ViewportSlot>
					{panel ? <span className="flicking-arrow-prev is-circle bg-accent" onClick={goPrev}></span> : ''}
					{panel != 2 && <span className="flicking-arrow-next is-circle bg-accent" onClick={goNext}></span>}
					{/* <div className="flicking-pagination"></div> */}
				</ViewportSlot>
			</Flicking>

			{<div className="flex flex-col text-dark-grey flex-1 lg:justify-end pb-[3vw]">
				<a href={`${kitchens[panel].link}`} className="mt-4 py-3 btn-accent text-white w-full bold text-center max-w-[400px] mx-auto inline-block start-btn active">
					{t("START EXPERIENCE")}
				</a>
			</div>}

		</main>
	</div>
}