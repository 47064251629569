import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n
    .use(initReactI18next)
    .init({
        lng: localStorage.lng || 'en',
        fallbackLng: 'en',
        ns: ['translation'],
        defaultNS: 'translation',
        // No backend needed since we're fetching manually
        interpolation: {
            escapeValue: false, // React already safes from XSS
        },
    });




// Function to fetch and transform the translations



// Export configured i18next instance
export default i18n;